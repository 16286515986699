@import 'card';

$primary-color: slateblue;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: Arial, Helvetica, sans-serif;
	background: $primary-color;
	color: #fff;
	line-height: 1.5;
}

ul {
	list-style: none;
}

.container {
	max-width: 1100px;
	margin: 0 auto;
	padding: 0 20px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

header {
	text-align: center;
	margin-bottom: 40px;
	background: rgba(0, 0, 0, 0.3);
	padding: 30px;
	border-bottom: 5px #fff solid;
}